$color-brand-ruby: #da2128;
$color-brand-marshmallow: #cf7a83;
$color-brand-carrot: #ff5a49;
$color-brand-ocean: #1874cf;
$color-brand-deep-ocean: #176ec5;
$color-brand-sky: #a9d4ff;
$color-brand-dark-sky: #67b3ff;
$color-brand-light-sky: #e9f4ff;
$color-brand-medium-sky: #d5eaff;
$color-brand-deepset-ocean: #11589d;

$color-gray-sauron: #031b4e;
$color-gray-obsidian: #080c17;
$color-gray-1: #454b53;
$color-gray-2: #8f9399;
$color-gray-3: #ebebeb;
$color-gray-4: #f6f6f6;
$color-gray-gandalf: #ffffff;

$color-state-info: #004fd8;
$color-state-success: #76ca66;
$color-state-warning: #fbc756;
$color-state-warning_P: #ffb71a;
$color-state-warning_H: #ffbf35;
$color-state-attention: #e0473b;
$color-state-attention_H: #dd2112;
$color-state-attention_P: #ba0000;
$color-state-success_H: #5eb14e;
$color-state-sucess_P: #3c972b;

$colors: (
  'color-brand-ruby': $color-brand-ruby,
  'color-brand-marshmallow': $color-brand-marshmallow,
  'color-brand-carrot': $color-brand-carrot,
  'color-brand-ocean': $color-brand-ocean,
  'color-brand-deep-ocean': $color-brand-deep-ocean,
  'color-brand-sky': $color-brand-sky,
  'color-brand-dark-sky': $color-brand-dark-sky,
  'color-brand-light-sky': $color-brand-light-sky,
  'color-brand-medium-sky': $color-brand-medium-sky,
  'color-brand-deepset-ocean': $color-brand-deepset-ocean,

  'color-gray-sauron': $color-gray-sauron,
  'color-gray-obsidian': $color-gray-obsidian,
  'color-gray-1': $color-gray-1,
  'color-gray-2': $color-gray-2,
  'color-gray-3': $color-gray-3,
  'color-gray-4': $color-gray-4,
  'color-gray-gandalf': $color-gray-gandalf,

  'color-state-info': $color-state-info,
  'color-state-success': $color-state-success,
  'color-state-warning': $color-state-warning,
  'color-state-warning_P': $color-state-warning_P,
  'color-state-warning_H': $color-state-warning_H,
  'color-state-attention': $color-state-attention,
  'color-state-attention_H': $color-state-attention_H,
  'color-state-attention_P': $color-state-attention_P,
  'color-state-success_H': $color-state-success_H,
  'color-state-sucess_P': $color-state-sucess_P,
);

:export {
  @each $color, $value in $colors {
    #{$color}: $value;
  }
}

$base_box_shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
$box_shadow_light: 0px 4px 18px rgba(0, 0, 0, 0.06);
$base_box_shadow_hovered: 0px 2px 7px rgba(0, 0, 0, 0.16),
  0px 4px 18px rgba(0, 0, 0, 0.08);

$big-desktop: 1920px;

@mixin bigDesktop {
  @media (min-width: #{$big-desktop}) {
    @content;
  }
}

// Typography table
$fonts: (
  //        1280+  |  1920+
  //name  fz   lg  |  fz
  h1: ((48px, 120%), (48px, 120%)),
  h2: ((32px, 120%), (48px, 120%)),
  h3: ((24px, 140%), (32px, 120%)),
  h4: ((22px, 140%), (32px, 120%)),
  large: ((18px, 160%), (22px, 140%)),
  main: ((16px, 160%), (18px, 160%)),
  small: ((14px, 140%), (16px, 160%)),
  tiny: ((12px, 140%), (14px, 140%))
);

$fonts-weight: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
);

// $weight can be default || light | regular | medium | bold
@mixin text($name, $weight: default) {
  // handle name incorrect
  @if $name
    !=h1 and
    $name
    !=h2 and
    $name
    !=h3 and
    $name
    !=h4 and
    $name
    !=large and
    $name
    !=main and
    $name
    !=small and
    $name
    !=tiny
  {
    @error 'Property name - #{$name} is incorrect;';
  }

  // handle weight incorrect
  @if (
    $weight
      !=default and
      $weight
      !=light and
      $weight
      !=regular and
      $weight
      !=medium and
      $weight
      !=bold
  ) {
    @error 'Property weight - #{$weight} is incorrect;';
  }

  $text-styles: map_get($fonts, $name);

  $val_1280: nth($text-styles, 1);
  $val_1920: nth($text-styles, 2);

  // Font Size & Line Height
  font-size: nth($val_1280, 1);
  line-height: nth($val_1280, 2);

  @include bigDesktop {
    font-size: nth($val_1920, 1);
    line-height: nth($val_1920, 2);
  }

  // Font Weight
  @if ($weight==default) {
    @if ($name==h1 or $name==h2 or $name==h3 or $name==h4) {
      font-weight: map_get($fonts-weight, medium);
    } @else {
      font-weight: map_get($fonts-weight, regular);
    }
  } @else {
    font-weight: map_get($fonts-weight, $weight);
  }
}

.card {
  width: 100%;

  .header {
    display: flex;
    margin-bottom: 7px;

    .title {
      flex: 1;
      height: 39px;
    }

    .buttons {
      display: flex;
      gap: 12px;

      button {
        width: fit-content;
      }
    }
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: 18px;

    &.row {
      gap: 0;
      justify-content: space-between;
      flex-direction: row;

      > div {
        width: fit-content;

        > input {
          width: 150px;
        }
      }
    }

    &.column {
      flex-direction: column;
    }

    &.disabled {
      > div {
        > input {
          cursor: not-allowed;
          pointer-events: none;

          background: #f6f6f6;
          border: 1px solid #ebebeb;
          border-radius: 2px;
        }
      }
    }

    &.input-direction-column {
      > div {
        width: 100%;

        > input {
          width: 100%;
        }
      }
    }

    &.input-direction-row {
      > div {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      & {
        > div {
          width: fit-content;
          gap: 17px;

          &:has(> p) {
            flex-wrap: wrap;
          }

          label {
            margin: 0;
          }

          > * {
            input {
              width: auto;
            }
          }

          > div {
            flex: 1;
          }

          > p {
            flex: 1 1 100%;
          }
        }
      }

      &:not(.row) {
        > div {
          width: 100%;
          gap: 0;
          flex-wrap: wrap;

          label {
            margin: 0;
          }

          > *:not(p) {
            flex: 1;

            > input {
              width: 100%;
              max-width: none;
            }
          }

          > p {
            flex: 1 1 100%;
          }
        }
      }
    }
  }
}
