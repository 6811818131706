$color-brand-ruby: #da2128;
$color-brand-marshmallow: #cf7a83;
$color-brand-carrot: #ff5a49;
$color-brand-ocean: #1874cf;
$color-brand-deep-ocean: #176ec5;
$color-brand-sky: #a9d4ff;
$color-brand-dark-sky: #67b3ff;
$color-brand-light-sky: #e9f4ff;
$color-brand-medium-sky: #d5eaff;
$color-brand-deepset-ocean: #11589d;

$color-gray-sauron: #031b4e;
$color-gray-obsidian: #080c17;
$color-gray-1: #454b53;
$color-gray-2: #8f9399;
$color-gray-3: #ebebeb;
$color-gray-4: #f6f6f6;
$color-gray-gandalf: #ffffff;

$color-state-info: #004fd8;
$color-state-success: #76ca66;
$color-state-warning: #fbc756;
$color-state-warning_P: #ffb71a;
$color-state-warning_H: #ffbf35;
$color-state-attention: #e0473b;
$color-state-attention_H: #dd2112;
$color-state-attention_P: #ba0000;
$color-state-success_H: #5eb14e;
$color-state-sucess_P: #3c972b;

$colors: (
  'color-brand-ruby': $color-brand-ruby,
  'color-brand-marshmallow': $color-brand-marshmallow,
  'color-brand-carrot': $color-brand-carrot,
  'color-brand-ocean': $color-brand-ocean,
  'color-brand-deep-ocean': $color-brand-deep-ocean,
  'color-brand-sky': $color-brand-sky,
  'color-brand-dark-sky': $color-brand-dark-sky,
  'color-brand-light-sky': $color-brand-light-sky,
  'color-brand-medium-sky': $color-brand-medium-sky,
  'color-brand-deepset-ocean': $color-brand-deepset-ocean,

  'color-gray-sauron': $color-gray-sauron,
  'color-gray-obsidian': $color-gray-obsidian,
  'color-gray-1': $color-gray-1,
  'color-gray-2': $color-gray-2,
  'color-gray-3': $color-gray-3,
  'color-gray-4': $color-gray-4,
  'color-gray-gandalf': $color-gray-gandalf,

  'color-state-info': $color-state-info,
  'color-state-success': $color-state-success,
  'color-state-warning': $color-state-warning,
  'color-state-warning_P': $color-state-warning_P,
  'color-state-warning_H': $color-state-warning_H,
  'color-state-attention': $color-state-attention,
  'color-state-attention_H': $color-state-attention_H,
  'color-state-attention_P': $color-state-attention_P,
  'color-state-success_H': $color-state-success_H,
  'color-state-sucess_P': $color-state-sucess_P,
);

:export {
  @each $color, $value in $colors {
    #{$color}: $value;
  }
}

$base_box_shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
$box_shadow_light: 0px 4px 18px rgba(0, 0, 0, 0.06);
$base_box_shadow_hovered: 0px 2px 7px rgba(0, 0, 0, 0.16),
  0px 4px 18px rgba(0, 0, 0, 0.08);

$big-desktop: 1920px;

@mixin bigDesktop {
  @media (min-width: #{$big-desktop}) {
    @content;
  }
}

// Typography table
$fonts: (
  //        1280+  |  1920+
  //name  fz   lg  |  fz
  h1: ((48px, 120%), (48px, 120%)),
  h2: ((32px, 120%), (48px, 120%)),
  h3: ((24px, 140%), (32px, 120%)),
  h4: ((22px, 140%), (32px, 120%)),
  large: ((18px, 160%), (22px, 140%)),
  main: ((16px, 160%), (18px, 160%)),
  small: ((14px, 140%), (16px, 160%)),
  tiny: ((12px, 140%), (14px, 140%))
);

$fonts-weight: (
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
);

// $weight can be default || light | regular | medium | bold
@mixin text($name, $weight: default) {
  // handle name incorrect
  @if $name
    !=h1 and
    $name
    !=h2 and
    $name
    !=h3 and
    $name
    !=h4 and
    $name
    !=large and
    $name
    !=main and
    $name
    !=small and
    $name
    !=tiny
  {
    @error 'Property name - #{$name} is incorrect;';
  }

  // handle weight incorrect
  @if (
    $weight
      !=default and
      $weight
      !=light and
      $weight
      !=regular and
      $weight
      !=medium and
      $weight
      !=bold
  ) {
    @error 'Property weight - #{$weight} is incorrect;';
  }

  $text-styles: map_get($fonts, $name);

  $val_1280: nth($text-styles, 1);
  $val_1920: nth($text-styles, 2);

  // Font Size & Line Height
  font-size: nth($val_1280, 1);
  line-height: nth($val_1280, 2);

  @include bigDesktop {
    font-size: nth($val_1920, 1);
    line-height: nth($val_1920, 2);
  }

  // Font Weight
  @if ($weight==default) {
    @if ($name==h1 or $name==h2 or $name==h3 or $name==h4) {
      font-weight: map_get($fonts-weight, medium);
    } @else {
      font-weight: map_get($fonts-weight, regular);
    }
  } @else {
    font-weight: map_get($fonts-weight, $weight);
  }
}

.table {
  width: 100%;
  overflow: hidden;
  height: 700px;

  :global .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 590px;

    &-scroll {
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }

  .scroll {
    width: 100%;

    :global .swiper-wrapper {
      height: 120px;
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
  }

  .notScroll {
    width: 100%;

    :global .swiper-wrapper {
      height: 310px;
      display: flex;
      align-items: flex-start;
      width: 100%;
    }
  }

  :global .swiper-slide-active {
    width: auto !important;
    min-width: 25%;
  }

  :global .swiper-slide {
    width: auto !important;
    min-width: 25%;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;

    .labelContainer {
      position: absolute;
      top: 16px;
      left: 16px;
      display: flex;
      flex-direction: column;
      gap: 70px;

      .label {
        position: relative;
        display: flex;
        gap: 4px;
        align-items: center;

        svg {
          cursor: pointer;
        }

        .tooltipWrapper {
          display: flex;
          align-items: center;
          height: fit-content;
        }

        .tooltip {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 400px;
          border-radius: 8px;
          color: $color-gray-gandalf;
          padding: 8px;
          background-color: $color-gray-1;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
            border-top: 11px solid transparent;
            border-bottom: 11px solid transparent;
            border-right: 8px solid $color-gray-1;
          }
        }
      }
    }

    .descriptionCard {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 16px 0 16px;
      border-right: 1px solid $color-gray-3;
    }

    .descriptionTable {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 16px 0 16px 16px;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      &Scroll {
        display: flex;
        flex-direction: row;
        position: fixed;
        gap: 10px;
        align-items: center;
        background-color: white;
        z-index: 3;
      }

      .name {
        cursor: pointer;

        &:hover {
          color: $color-brand-ocean !important;
        }

        &Scroll {
          width: 100px;
        }
      }

      .image {
        width: 215px;
        height: 187px;
        align-self: center;
        display: flex;
        margin-bottom: 8px;
        cursor: pointer;
        transition-duration: 0.5s;

        &Scroll {
          width: 103px;
          height: 90px;
        }
      }
    }

    .more {
      margin-top: 32px;
      width: 100%;
    }
  }

  .subTitle {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-top: 14px;
    margin-bottom: 16px;
    height: 31px;
    margin-left: 16px;
  }

  .toolTip {
    height: 16px;

    & > div:first-child {
      height: 16px;
    }
  }

  .info {
    padding: 16px 0px;
    min-height: 89px;

    .label {
      height: 22px;
      max-width: 300px;
      z-index: 1;

      .tooltip {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    .value {
      margin-top: 16px;
      width: 100%;
      border-right: 1px solid $color-gray-3;
    }

    div + div {
      border-right: 1px solid $color-gray-3;
    }

    &Back {
      background-color: $color-gray-4;
    }
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 130px;
  z-index: 1;
  margin: 0px 3px;

  &Scroll {
    top: 40px;
  }

  .control {
    box-shadow: $base_box_shadow;
    border-radius: 55px;
    padding: 0;
    width: 36px;
    height: 36px;

    &Scroll {
      width: 32px;
      height: 32px;
    }

    &Left {
      position: absolute;
      left: 5px;

      :global svg {
        margin-right: 0 !important;
      }
    }

    &Right {
      position: absolute;
      right: 7px;

      :global svg {
        margin-left: 0 !important;
      }
    }
  }
}

@include bigDesktop {
  .table {
    height: 800px;

    :global .swiper-wrapper {
      height: 685px;
    }

    .container {
      .labelContainer {
        position: absolute;
        top: 16px;
        left: 16px;
        display: flex;
        flex-direction: column;
        gap: 63px;

        .tooltip {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}

@media (max-height: 600px) {
  .table {
    height: 535px;

    :global .swiper-wrapper {
      height: 425px;
    }
  }
}

@media (max-height: 700px) {
  .table {
    height: 535px;

    :global .swiper-wrapper {
      height: 425px;
    }
  }
}
