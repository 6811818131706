@font-face {
  font-family: 'Mazzard M';
  src: url('MazzardM-Medium.eot');
  src: local('Mazzard M Medium'), local('MazzardM-Medium'),
    url('MazzardM-Medium.eot?#iefix') format('embedded-opentype'),
    url('MazzardM-Medium.woff2') format('woff2'),
    url('MazzardM-Medium.woff') format('woff'),
    url('MazzardM-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard M';
  src: url('MazzardM-Light.eot');
  src: local('Mazzard M Light'), local('MazzardM-Light'),
    url('MazzardM-Light.eot?#iefix') format('embedded-opentype'),
    url('MazzardM-Light.woff2') format('woff2'),
    url('MazzardM-Light.woff') format('woff'),
    url('MazzardM-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard M';
  src: url('MazzardM-Bold.eot');
  src: local('Mazzard M Bold'), local('MazzardM-Bold'),
    url('MazzardM-Bold.eot?#iefix') format('embedded-opentype'),
    url('MazzardM-Bold.woff2') format('woff2'),
    url('MazzardM-Bold.woff') format('woff'),
    url('MazzardM-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard M';
  src: url('MazzardM-Regular.eot');
  src: local('Mazzard M Regular'), local('MazzardM-Regular'),
    url('MazzardM-Regular.eot?#iefix') format('embedded-opentype'),
    url('MazzardM-Regular.woff2') format('woff2'),
    url('MazzardM-Regular.woff') format('woff'),
    url('MazzardM-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
